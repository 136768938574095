import React from 'react';

import './Services.css';

import Footer from '../../components/Footer/Footer';
import { servicesData } from './serviceData';

const Services = () => {
  document.title = 'Áreas de Atuação - Gomes & Mata Advogados Associados';
  
  const servicesList = servicesData.map((item) => {
      return (
          <div 
            className='services__item'
            key={item.id}
            >
              <span className='services__item__text'>
                <h3 className='services__item__text__title'>{item.title}</h3>
              </span>
            { 
              item.list.map((li) => {
              return (
                <ul 
                  className='services__item__list'
                  key={li.id}
                  >
                    <li className='services__item__list__li'>{ li.title }</li>
                </ul>
                );
              })
            }
          </div>
        );
  });

  return (
      <React.Fragment>
          <section className='services'>
            <h1 className='services__title'>ÁREAS DE ATUAÇÃO</h1>
            { servicesList }
          </section>
          <Footer />
      </React.Fragment>
        
  )
}

export default Services