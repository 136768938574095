export const teamData = [
    {
        id: 1,
        name: 'Dênis Gomes',
        photo: 'lawyer-dg.jpg', 
        role: 'Advogado Associado',
        description: 'Graduado em Direito pelo Centro Universitário-UDF e Pós-graduado em Direito Civil e Direito Processual Civil pela Faculdade Unyleya. Advogado especialista em Direito Previdenciário com foco na consultoria técnica e defesa dos beneficiários, além de atuar diretamente nas demandas cíveis e trabalhistas do escritório.'
    },
    {
        id: 2,
        name: 'Luana da Mata',
        photo: 'lawyer-lm.jpg', 
        role: 'Advogada Associada',
        description: 'Advogada graduada em Direito pelo Centro Universitário de Brasilia - UniCeub, Pós-graduada em Direito e Processo do Trabalho pelo Complexo de Ensino Renato Saraiva e Pós-graduada em Advocacia Cível pela Fundação da Escola Superior do Ministério Público - ESAN. Advogada especialista em Direito previdenciário, cível e trabalhista com foco na área consultiva e contenciosa do escritório atuando diretamente na resolução das demandas administrativas e judiciais.',
    }
]