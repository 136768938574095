import React from 'react';
import { NavLink } from 'react-router-dom';

import './Error.css';

import Navbar from '../../components/NavBar/NavBar';
import Footer from '../../components/Footer/Footer';

const Error = () => {
  document.title = 'Pagina não encontrada - Gomes & Mata Advogados Associados';

  return (
    < React.Fragment>
      <Navbar />
      <section className='error__container'>
        <h1>404</h1>
        <h3>ESSA PÁGINA NÃO FOI ENCONTRADA</h3>
        <p>
          Desculpe, a página na qual você está não existe. <NavLink to='/'>Clique aqui</NavLink> para voltar a página inicial ou use a barra de navegação acima para outras páginas
        </p>
      </section>
      <Footer />
    </React.Fragment>
    
  )
}

export default Error