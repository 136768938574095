import React from 'react';

import './Home.css';

import Footer from '../../components/Footer/Footer';

const Home = () => {
  document.title = 'Gomes & Mata Advogados Associados';

  return (
    <React.Fragment>
    <div className='home__banner'></div>
      <section className='home'>
        <div className='home__intro'>
          <p>
            O Escritório Gomes & Mata Advogados Associados é especializado nas áreas de Direito Previdenciário, Direito do Trabalho e Direito Civil.
          </p>
          <p>
            O nosso conceito de atendimento é 100% personalizado. A necessidade do cliente é a prioridade na execução do serviço,  com competência técnica, seriedade e diálogo profissional com as instituições de justiça.
          </p>
        </div>
        <div className='home__services'>
          <h3>
            FACILITAMOS SUA VIDA NAS SEGUINTES ÁREAS
          </h3>
          <ul className='home__services__list'>
          <li className='home__services__list__item'>
            <span>DIREITO PREVIDENCIÁRIO</span>
          </li>
          <li className='home__services__list__item'>
            <span>DIREITO DO TRABALHO</span>
          </li>
          <li className='home__services__list__item'>
            <span>DIREITO CIVIL</span>
          </li>
          </ul>
          
          
        </div>
      </section>
      <Footer />
    </React.Fragment>
  )
}

export default Home