import './Footer.css';

import LogoWhite from '../../images/logo-white.svg';
import WhatsAppLogo from '../../images/whatsapp.svg';
import MapIcon from '../../images/map.svg';
import TimerIcon from '../../images/timer.svg';
import PhoneIcon from '../../images/phone.svg';
import EmailIcon from '../../images/mail.svg';

const Footer = () => {
  return (
    <footer>
      <div className='footer__head'>
        <img src={ LogoWhite } className='footer__logo' alt='Gomes & Mata Logo'/>
      </div>

      <div className='footer__catalog'>
        <div className='footer__catalog__info'>
          <h3>ENDEREÇO</h3>
          <div className='footer__catalog__info__link'>
            <img src={MapIcon} alt='Map Icon' />       
            <a href='https://goo.gl/maps/5wPeGGncb3FjbLcDA'>
                Quadra 07, Lote Especial nº 04, Sala 108,
                Ed. Multishopping, Sobradinho, Brasilia-DF
            </a>
          </div>
        </div>
        <div className='footer__catalog__info'>
          <h3>HORÁRIO DE ATENDIMENTO</h3>
          <div className='footer__catalog__info__link'>
            <img src={TimerIcon}  alt='Timer Icon'/>
            <a>
              09h às 19h - Segunda a Sexta-feira 
            </a>
          </div>
        </div>
        <div className='footer__catalog__info'>
          <h3>CONTATO</h3>
          <div className='footer__catalog__info__list'>
            <div className='footer__catalog__info__link'>
              <img src={PhoneIcon} alt='Phone Icon'/>
              <a href='tel:+556134537726'>
                (61) 3453-7726
              </a>
            </div>
            <div className='footer__catalog__info__link'>
              <img src={WhatsAppLogo} alt='WhatsApp Icon'/>
              <a href='https://wa.me/556191416666'>
                (61) 99141-6666
              </a>
            </div>
            <div className='footer__catalog__info__link'>
              <img src={EmailIcon} alt='Email Icon'/>
              <a href='mailto:gomes.adv.mata@gmail.com'>
                gomes.adv.mata@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className='footer__bottom'>
        <hr/>
        <p>©{ new Date().getFullYear() } Gomes & Mata Advogados Associados - Todos os Direitos Reservados</p>
      </div>
    </footer>
  );
};

export default Footer;