import React from 'react';

import './Contact.css';

import Footer from '../../components/Footer/Footer';
import { contactData } from './contactData';

const Contact = () => {
  document.title = 'Contato - Gomes & Mata Advogados Associados';

  const contactList = contactData.map((item) => {
    return (
     <div 
      className='contact__details'
      key={item.id}
      >
        <h3 className='contact__details__title'>{item.title}</h3>
        {
          item.details.map((i) => {
            return(
              <a 
                href={ i.link !== null ? i.link : '#'}
                className='contact__details__link'
                key={ i.id }
                >
                  <img
                    className='contact__details__link-icon' 
                    src={ require(`./../../images/${ i.image }`) }
                    alt={ i.alt }
                  />
                  <span
                    className='contact__details__link-text' 
                    >{ i.title }
                  </span>
              </a>
            )
          })
        }
     </div> 
    )
  });

  return (
    <React.Fragment>
        <section className='contact'>
          { contactList }
          <iframe 
            title='map frame'
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15368.127843184515!2d-47.82420117169796!3d-15.643286426323474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x935a4128b739a417%3A0x25bf0955365d91eb!2sGomes%20%26%20Mata%20Advogados%20Associados!5e0!3m2!1spt-BR!2sbr!4v1679588849085!5m2!1spt-BR!2sbr" 
            width="600" 
            height="450" 
            style={{ border:0 }}  
            allowfullscreen="" 
            loading="lazy" 
            referrerpolicy="no-referrer-when-downgrade"></iframe>
        </section>
        <Footer />
    </React.Fragment>
  );
};

export default Contact;