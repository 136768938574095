import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Contact from './pages/Contact/Contact';
import Error from './pages/Error/Error';
import RootLayout from './pages/RootLayout/RootLayout';

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout />,
        errorElement: <Error />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: '/sobre-nos',
                element: <About />
            },
            {
                path: '/areas-de-atuacao',
                element: <Services />
            },
            {
                path: '/contato',
                element: <Contact />
            }
        ]   
    } 
])

const App = () => {
    return(
        <RouterProvider router={router} />
    );
};

export default App;