import React from 'react';

import './About.css';

import Footer from '../../components/Footer/Footer';
import { teamData } from './teamData';

const About = () => {
  document.title = 'Sobre Nós - Gomes & Mata Advogados Associados';
  
  const teamList = teamData.map((member) => {
    return (
      <div
        className='about__team__member'
        key={ member.id }
      > 
        <img 
          className='about__team__member__photo'
          src={require(`./../../images/${ member.photo }`)} 
          alt='advogado'
          />
        <div className='about__team__member__info'>
          <h3>{ member.name }</h3>
          <h5>{ member.role }</h5>
          <p>{ member.description }</p>
        </div>
      </div>
    )
  });
  
  return (
    <React.Fragment>
        <div className='about__banner'></div>
        <section className='about'>
          <div className='about__office'>
            <div className='about__office__text'>
              <h3>ESCRITÓRIO</h3>
              <p>
                O Escritório nasceu com a finalidade de atender as áreas de Direito Previdenciário Público e Privado. Nosso foco de atuação abrange as áreas de Direito Previdenciário (Regime Geral-INSS), Regimes Próprios (União Estados e Municípios) Direito do Trabalho e Cível. 
              </p>
              <p>
                O escritório é pensado e estruturado para atendimento personalizado, com resposta qualificada e individualizada nas questões submetidas por nossos clientes. 
              </p>
            </div>
            
          </div>
          <div className='about__team'>
            <h3>EQUIPE</h3>
            { teamList }
          </div>
        </section>
        <Footer />
    </React.Fragment>
  )
}

export default About