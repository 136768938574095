export const servicesData = [
    { 
        id: 1,
        title: 'Direito Previdenciário',
        list: [
            {   id: 1,
                title: 'Ação para reconhecimento de tempo especial'
            },
            {
                id: 2,
                title: 'Análise e planejamento de aposentadorias'
            },
            {
                id: 3,
                title: 'Aposentadoria Especial (Insalubridade/Periculosidade)'
            },
            {
                id: 4,
                title: 'Aposentadoria Especial da Pessoa com Deficiência',
            },
            {
                id: 5,
                title: 'Aposentadoria por Idade e por Tempo de Contribuição'
            }, 
            {
                id: 6,
                title: 'Auxílio-Acidente, Auxílio-Doença e Aposentadoria por Invalidez'
            },
            {
                id: 7,
                title: 'Contribuições retroativas para contar como tempo de contribuição'
            },
            {
                id: 8,
                title: 'Orientação previdenciária de acordo com a legislação atual e com a Reforma da Previdência'
            },
            {
                id: 9,
                title: 'Pensão por Morte',
            },
            {
                id: 10,
                title: 'Regularização do CNIS: averbação de tempo não considerado pelo INSS'
            }
        ]
    },
    {
        id: 2,
        title: 'Direito Trabalhista',
        list: [
            {
                id: 1,
                title: 'Ações Acidentárias'
            },
            {
                id: 2,
                title: 'Ações de empregados públicos  (servidores regidos pela CLT)'
            },
            {
                id: 3,
                title: 'Ações de todas as categorias profissionais '
            }
        ]
    },
    {
        id: 3,
        title: 'Direito Cível',
        list: [
            {
                id: 1,
                title: 'Ações obrigacionais;'
            },
            {
                id: 2,
                title: 'Ações indenizatórias (Dano Moral e Material)'
            },
            {
                id: 3,
                title: 'Interdições'
            },
            {
                id: 4,
                title: 'Planos de Saúde'
            },
            {
                id: 5,
                title: 'Partilhas'
            },
            {
                id: 6,
                title: 'Seguros'
            }
        ]
    }
];
