import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import './NavBar.css';

import Logo from '../../images/logo.svg';
import WhatsAppLogo from '../../images/whatsapp.svg';
import WhatsAppLogoOrange from '../../images/whatsapp-orange.svg'

const NavBar = () => {
    const [ showMenuIcon, setShowMenuIcon ] = React.useState(false);

    const handleClick = () => {
        setShowMenuIcon((prev) => !prev);
    };

    return (
        <nav>
            <Link to='/'>
                <img className='nav__logo' src={Logo} alt='Gomes & Mata Logo'/>
            </Link>
                <div>
                    <a
                        href='https://api.whatsapp.com/send/?phone=556191416666&text=Ol%C3%A1%2C+gostaria+de+agendar+uma+consulta%21&type=phone_number&app_absent=0'
                        className='whatsapp-link'>
                        <img src={ WhatsAppLogoOrange } alt='whatsapp logo'/>
                    </a>
                    <div className='nav__menu-icons' onClick={handleClick}>
                        <i className={showMenuIcon ? 'fas fa-times fa-xl' : 'fas fa-bars fa-xl'}></i>
                    </div> 
                </div>
            <ul className={showMenuIcon ? 'nav__menu active' : 'nav__menu'}>
                <li>
                    <NavLink 
                        to='/' 
                        className={ ({ isActive }) => isActive ? 'nav__item-active' : 'nav-item' }
                        onClick={handleClick}
                        end
                        >
                            HOME
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        to='/sobre-nos' 
                        className={ ({ isActive }) => isActive ? 'nav__item-active' : 'nav-item' }
                        onClick={handleClick}
                        >
                            SOBRE NÓS
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        to='/areas-de-atuacao' 
                        onClick={handleClick}
                        className={ ({ isActive }) => isActive ? 'nav__item-active' : 'nav-item' }
                        >   
                            ÁREAS DE ATUAÇÃO
                    </NavLink>
                </li>
                <li>
                    <NavLink 
                        to='/contato' 
                        onClick={handleClick}
                        className={ ({ isActive }) => isActive ? 'nav__item-active' : 'nav-item' }
                        >
                            CONTATO
                    </NavLink>
                </li>
                <li>
                    <a 
                        href='https://api.whatsapp.com/send/?phone=556191416666&text=Ol%C3%A1%2C+gostaria+de+agendar+uma+consulta%21&type=phone_number&app_absent=0'
                        className='nav__button__whatsapp'
                        >
                        <img src={WhatsAppLogo} alt='WhatsApp Logo'/>
                        AGENDE UMA CONSULTA
                    </a>
                </li>
            </ul>
        </nav>
    )
};

export default NavBar;