export const contactData = [
    {
        id: 1,
        title: 'CONTATO',
        details: [
            {
                id: 1,
                image: 'phone-black.svg',
                alt: 'phone icon',
                title: '(61) 3453-7726',
                link: 'tel:+556134537726'
            },
            {
                id: 2,
                image: 'whatsapp-black.svg',
                title: '(61) 99141-6666',
                alt: 'icone do whatsapp',
                link: 'https://wa.me/556191416666'
            },
            {
                id: 3,
                image: 'email-black.svg',
                alt: 'icone de email',
                title: 'gomes.adv.mata@gmail.com',
                link: 'mailto:gomes.adv.mata@gmail.com'
            },
        ]
    },
    {
        id: 2,
        title: 'ENDEREÇO',
        details: [
            {
                id: 1,
                image: 'map-black.svg',
                title: 'Quadra 07, Lote Especial nº 04, Sala 108, Edifício Multishopping, Sobradinho, Brasília-DF',
                link: 'https://goo.gl/maps/5wPeGGncb3FjbLcDA',
                alt: 'icone do mapa',
            }
        ]
    },
    {
        id: 3,
        title: 'HORÁRIO DE ATENDIMENTO',
        details: [
            {
                id: 1,
                image: 'timer-black.svg',
                title: '09h às 19h - Segunda a Sexta-feira',
                alt: 'icone do relogio',
                link: null
            }
        ]
    }
];